import React, { useRef, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import emailjs from '@emailjs/browser';
import Logo from './assets/Trafari_MainLogo1.png';
import Sports_Pin from './assets/Sports_Pin.png';
import StudyGroup_Pin from './assets/StudyGroup_Pin.png';
import Fundraiser_Pin from './assets/Fundraiser_Pin.png';
import Party_Pin from './assets/Party_Pin.png';
import DemoImage from './assets/Demo_Image.png'; // Your new overlay image
import SmallImage1 from './assets/Trafari_Mascot.png';
import SmallImage2 from './assets/Trafari_Mascot.png';
import SmallImage3 from './assets/Trafari_Mascot.png';
import './App.css';
import StudentForm from './StudentForm';
import AdminForm from './AdminForm';

function App() {
  const formRef = useRef(null);
  const imageRefs = useRef([]);

  const directions = [
    { x: 1, y: -1 },
    { x: -1, y: -1 },
    { x: 1, y: -2 },
    { x: -1, y: -2 },
    { x: 2, y: -1 },
    { x: -2, y: -1 },
  ];

  const mod = (n, m) => ((n % m) + m) % m;

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;

      imageRefs.current.forEach((image, index) => {
        if (image) {
          const speed = index / 6 + 1;
          const dir = directions[mod(index, directions.length)];

          const translateX = scrollY * speed * dir.x;
          const translateY = scrollY * speed * dir.y;

          image.style.transform = `translate(${translateX}px, ${translateY}px)`;
        }
      });
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        'service_qj3r9n9',
        'template_v8djreh',
        e.target,
        'PoouU1nqi8Rn79fj0'
      )
      .then(
        (result) => {
          console.log(result.text);
          alert('Message sent!');
        },
        (error) => {
          console.log(error.text);
          alert('An error occurred, please try again.');
        }
      );

    e.target.reset();
  };

  return (
    <Router>
      <div className="App">
        <Routes>
          {/* Main Page */}
          <Route
            path="/"
            element={
              <>
                <div className="image-container">
                  {/* Moving Images */}
                  <div className="moving-images">
                    <img ref={(el) => (imageRefs.current[0] = el)} alt="mascot" src={SmallImage1} className="moving image moving-image1" />
                    <img ref={(el) => (imageRefs.current[1] = el)} alt="mascot" src={SmallImage2} className="moving image moving-image2" />
                    <img ref={(el) => (imageRefs.current[2] = el)} alt="mascot" src={SmallImage3} className="moving image moving-image3" />
                    <img ref={(el) => (imageRefs.current[3] = el)} alt="mascot" src={SmallImage1} className="moving image moving-image4" />
                    <img ref={(el) => (imageRefs.current[4] = el)} alt="mascot" src={SmallImage2} className="moving image moving-image5" />
                    <img ref={(el) => (imageRefs.current[5] = el)} alt="mascot" src={SmallImage3} className="moving image moving-image6" />
                    <img ref={(el) => (imageRefs.current[6] = el)} alt="mascot" src={SmallImage1} className="moving image moving-image7" />
                    <img ref={(el) => (imageRefs.current[7] = el)} alt="mascot" src={SmallImage2} className="moving image moving-image8" />
                    <img ref={(el) => (imageRefs.current[8] = el)} alt="mascot" src={SmallImage3} className="moving image moving-image9" />
                    <img ref={(el) => (imageRefs.current[9] = el)} alt="mascot" src={SmallImage1} className="moving image moving-image10" />
                    <img ref={(el) => (imageRefs.current[10] = el)} alt="mascot" src={SmallImage2} className="moving image moving-image11" />
                    <img ref={(el) => (imageRefs.current[11] = el)} alt="mascot" src={SmallImage3} className="moving image moving-image12" />
                    <img ref={(el) => (imageRefs.current[12] = el)} alt="mascot" src={SmallImage3} className="moving image moving-image13" />
                    <img ref={(el) => (imageRefs.current[13] = el)} alt="mascot" src={SmallImage3} className="moving image moving-image14" />
                    <img ref={(el) => (imageRefs.current[14] = el)} alt="mascot" src={SmallImage3} className="moving image moving-image15" />
                    <img ref={(el) => (imageRefs.current[15] = el)} alt="mascot" src={SmallImage3} className="moving image moving-image16" />
                 </div>
                 <div className="center-content1">
                  <img src={Logo} alt="Logo" className="Logo" />
                  <div className="overlay">
                    <p>
                      Empowering colleges and universities to build stronger communities by
                      simplifying access to local events
                    </p> <br /><br />
                    <p> <span className="extra-small-text">
                      Interested in Trafari on your campus?</span>
                    </p>
                  </div>
                  <div className="button-group">
                    <Link to="/students" className="button button-student">
                      Students
                    </Link>
                    <Link to="/administrators" className="button button-admin">
                      Administrators
                    </Link>
                  </div>
                </div>

                {/* Demo overlay image positioned slightly to the right */}
                <img
                  src={DemoImage}
                  alt="Demo Overlay"
                  className="demo-overlay-image"
                />
              </div>

                <div className="image-container">
                  <div className="overlay1">
                    <img src={Sports_Pin} alt="Sports Pin" className="sp-image" />
                    <img src={StudyGroup_Pin} alt="Study Group Pin" className="sgp-image" />
                    <img src={Fundraiser_Pin} alt="Fundraiser Pin" className="fp-image" />
                    <img src={Party_Pin} alt="Party Pin" className="pp-image" />
                    <div className="center-content">
                      <p>
                        <span className="large-text">Stay in the Loop on Campus Events</span><br /><br />
                        <span className="small-text">Swipe through to discover the latest happenings, make new friends, snag free food, and find your next adventure.</span><br /><br /><br /><br /><br /><br />
                      </p>
                      <p>
                        <span className="large-text">Promote Your Event in a Flash</span><br /><br />
                        <span className="small-text">Create and share events in seconds. Watch your community connections grow with every post!</span>
                      </p>
                    </div>
                  </div>
                </div>

                {/* Form Section */}
                <div className="form-section" ref={formRef}>
                  <h2>Contact Us</h2>
                  <form onSubmit={sendEmail}>
                    <label>Name:</label>
                    <input type="text" name="name" required />

                    <label>Email:</label>
                    <input type="email" name="email" required />

                    <label>Description:</label>
                    <textarea name="message" required />

                    <button type="submit">Submit</button>
                  </form>
                </div>
              </>
            }
          />

          {/* Students Form Page */}
          <Route path="/students" element={<StudentForm />} />

          {/* Administrators Form Page */}
          <Route path="/administrators" element={<AdminForm />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
