import React from 'react';
import { Widget } from '@typeform/embed-react';

function StudentForm() {
  return (
    <div className="typeform-container">
      <Widget
        id="https://boi8kg4u4t6.typeform.com/to/PV326apn" // Replace with your actual Typeform ID for students
        style={{ width: '100%', height: '100vh' }}
        className="my-typeform"
      />
    </div>
  );
}

export default StudentForm;
